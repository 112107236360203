<template>
  <v-footer
    padless
    class="d-flex flex-column justify-center"
  >
    <strong>{{ siteName }}</strong>
  </v-footer>
</template>

<script lang="babel" type="text/babel">
export default {
  data: () => ({
    
  }),
  computed: {
    siteName() {
      return this.$store.getters['base/siteName']
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>